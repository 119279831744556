body {
  background: hsl(200, 30%, 98%);
}

body.dark-mode {
  background: hsl(200, 15%, 10%);
}

a {
  text-decoration: none;
}

svg {
  width: 48px;
  height: 48px;
}
