.slick-track {
  display: flex;
}
.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-slide {
  margin-right: 16px;
  outline: none;
}
.slick-dots {
  text-align: center;
  padding-left: 0;
}
.slick-dots > li {
  position: relative;
  display: inline-block;
  margin-right: 4px;
  padding: 0;
}
.slick-dots > li:last-child {
  margin-right: 0;
}
.slick-dots > li > button {
  width: 32px;
  height: 6px;
  border-radius: 3px;
  border: none;
  /* TODO: theme化出来る？ */
  background-color: #e0e0e0;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  outline: none;
}
.slick-dots > li.slick-active > button {
  width: 64px;
  /* TODO: theme化出来る？ */
  background-color: #eb5757;
}
